import FormValidator from '@/js/components/formValidator'
import ImageUpload from '@/js/components/imageUpload'
import Modal from '@/js/components/modal'

const main = () => {
    if(document.getElementById('page-entry-input-step01') === null) return

    const formValidator = new FormValidator({
        renderAreaSelector: '#input',
        renderErrorCnt: false,
        formObjSelector: 'form',
    })

}

export default main;