import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import { alertAction, innerScroll } from "@/js/components/helpers";
import gtag, { install } from 'ga-gtag'
import { set } from "lodash";

// Google Analytics
const setGtag = (ga_id) => {
    if(!ga_id) return
    install(ga_id)

    // HTML内直書きのgtag対応 
    window.gtag = gtag;
}

const globalHeaderAction = () => {
    $('.global-nav .list > p').on('click', (e) => {
        let obj = e.currentTarget
        if($(obj).hasClass('open')){
            $(obj).removeClass('open')
        }else{
            $(obj).addClass('open')
        }
    })
}


const main = () => {
    const ga_id = document.querySelector('meta[name="ga-id"]').getAttribute('content')
    setGtag(ga_id)
    alertAction()
    innerScroll()
    globalHeaderAction()
}

export default main;