/**
 * モーダル表示
 *
 * Author : T.Tomida <tomida@linkainc.jp>
 * Company : LINKA ASSOCIATES INC.
 * Copyright : Copyright(c) 2020 LINKA ASSOCIATES INC.
 * ver. 1.0.0
 */
export default class {
  constructor(params) {
    this.settings = Object.assign({}, this.defaults(), params)
  }

  defaults() {
    return {
      target: 'body',
      modalId: 'modal',
      el: '',
      activeClass: 'active',
      overlayClose: true,
      closeAfterFunc: null,
    }
  }

  setHtml(html) {
    let div = document.createElement('div')
    div.innerHTML = html
    this.setDom(div)
    return this
  }

  setDom(dom) {
    this.settings.el = dom
    return this
  }

  show() {
    this.settings.scrPos = $(window).scrollTop();
    this.createDom = this.createHtml()

    this.removeElement(document.getElementById(this.settings.modalId))
    document.querySelector(this.settings.target).appendChild(this.createDom)

    setTimeout(() => {
      this.createDom.classList.add(this.settings.activeClass)
    }, 100)

    this.createDom.querySelector('.modal-close').addEventListener('click', () => {
      this.close()
    })
    if (this.settings.overlayClose) {
      this.createDom.querySelector('.modal-overlay').addEventListener('click', () => {
        this.close()
      })
    }
  }

  removeElement(element) {
    if (!element) return
    element.parentNode.removeChild(element)
  }

  close(removeFlg = true) {
    this.createDom.classList.remove(this.settings.activeClass)
    if (removeFlg) {
      this.settings.el.parentNode.removeChild(this.settings.el)
    }
    if (this.settings.closeAfterFunc) {
      this.settings.closeAfterFunc()
    }
  }

  createHtml() {
    let div = document.createElement('div')
    div.setAttribute('id', this.settings.modalId)
    let baseHtml = '<div class="modal-overlay"></div><div class="modal-content-wrap"><div class="modal-content"></div><div class="modal-close"></div></div>'
    div.innerHTML = baseHtml

    div.querySelector('.modal-content').appendChild(this.settings.el)
    return div
  }
}
