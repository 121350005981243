

const main = () => {
    if (document.getElementById('page-entry-confirm') === null) return

    // 送信ボタンの二重クリック禁止
    document.querySelector('#send').addEventListener('submit', (e) => {
        e.currentTarget.querySelector('button[value="submit"]').classList.add('is-loading')
        return true
    })  
}

export default main;