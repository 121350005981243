import { stringify } from 'qs'
import 'jquery.easing';

export const innerScroll = () => {
    $('a[href*="#"]').each(function () {
        $(this).on('click', function () {
            let pageKey = $(this).attr('href').replace(/^.*?#/, '#');
            if (!pageKey) {
                return true;
            }

            let pos = pageKey == '#' ? 0 : $(pageKey).position().top;
            $('body, html').animate({ scrollTop: pos }, 500, 'easeOutQuint');
            return false;
        })
    })
}

export const imgPreload = imgPathList => {
    return Promise.all(imgPathList.map(_path => {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => {
                resolve();
            }
            img.onerror = () => {
                reject();
            }
            img.src = _path;
        })
    }))
}

export const loadTemplate = (id) => {
    const content = document.querySelector(`#${id}`).content
    return document.importNode(content, true)
}

export const alertAction = () => {
    $('body .alert').delay(3000).fadeOut(200).queue(function () {
        this.remove()
    })

    $('body .alert').on('click', e => {
        $(e.currentTarget).stop().fadeOut()
    })
}

export const submitFetch = async (path, type, data = null, params = {}) => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    let send = Object.assign({}, params, {
        method: type == "GET" ? "GET" : "POST",
        credentials: 'same-origin',
        headers: {
            'X-CSRF-TOKEN': token
        },
    })
    switch (type) {
        case 'GET':
            if (data) {
                let bodyStringify = stringify(data)
                path = `${path}?${bodyStringify}`
            }
            break
        default:
            let formData = new FormData()
            for (let key in data) {
                formData.append(key, data[key]);
            }
            if (type == "PUT" || type == "PATCH" || type == "DELETE") {
                formData.append('_method', type)
            }
            Object.assign(send, { body: formData })
    }

    let resp = await fetch(path, send)
    return resp.json()
}

export const triggerEvent = (element, event) => {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent(event, true, true); // event type, bubbling, cancelable
    return element.dispatchEvent(evt);
}