import FormValidator from '@/js/components/formValidator'
import ImageUpload from '@/js/components/imageUpload'
import Modal from '@/js/components/modal'
import privacyHtml from '@/html/modal-privacy.html'

const main = () => {
    if (document.getElementById('page-entry-input-step02') === null) return

    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    const formValidator = new FormValidator({
        renderAreaSelector: '#input',
        renderErrorCnt: false,
        formObjSelector: 'form',
    })

    document.querySelector('.modal-open').addEventListener('click', () => {
        const modal = new Modal()
        modal.setHtml(privacyHtml)
        modal.show()
    })

    const imageUpload = new ImageUpload({
        targetSelector: '.item.image',
        typeFilter: ['image.*'],
        fileSizeLimit: 12,
        token
    })

    imageUpload.checkFile = (element) => {
        const chkElement = $(element).find('.preview img')
        const chkHash = chkElement.attr('data-hash');
        let result = true;
        $(element).closest('.item-box').find('.hash').val(chkHash)
        $('.preview img').not(chkElement).each((i, el) => {
            if ($(el).attr('data-hash') === chkHash) {
                alert('同じファイルが選択されています。');
                imageUpload.deletedFile(element);
                $(element).closest('.item-box').find('.hash').val('')
                result = false;
                return false;
            }
        });
        return result;
    }

    const setDelete = (obj) => {
        obj.addEventListener('click', (e) => {
            if (!window.confirm('選択した画像をリセットします。よろしいですか？')) return
            imageUpload.deletedFile(e.target.closest('.item.image'))
        })
    }

    // 編集モードの場合の削除ボタンアクションを設定
    document.querySelectorAll('.item.image .delete').forEach((el) => {
        setDelete(el)
    });


    const setBoxParts = (obj, targetKey) => {
        const selectCount = $(obj.target).val();
        const itemCount = $(`.js-list-${targetKey}`).children().length;
        if (itemCount > selectCount) {
            if (!window.confirm('入力済みの項目が削除される可能性があります。よろしいですか？')) {
                let prevCnt = $(e.target).attr('data-cnt')
                $(e.target).val(prevCnt)
                return false;
            }
            for (let i = 0; i < itemCount - selectCount; i++) {
                $(`.js-list-${targetKey}`).children().last().remove();
            }
        } else {
            const template = document.querySelector('#item-box-parts').content.cloneNode(true);

            for (let i = itemCount; i < selectCount; i++) {
                const clone = template.cloneNode(true);

                clone.querySelectorAll('input[name],textarea').forEach((el) => {
                    const name = el.getAttribute('name')
                    el.setAttribute('name', `work_${targetKey}[${i}][${name}]`)
                })

                clone.querySelectorAll('.input-check-container').forEach((el) => {
                    formValidator.addTargetObject(el);
                })

                clone.querySelector('.item.image .file-up input[type="file"]').setAttribute('id', `${targetKey}-file-${i}`)
                clone.querySelector('.item.image .edit').setAttribute('for', `${targetKey}-file-${i}`)

                clone.querySelectorAll('.item.image').forEach((el) => {
                    imageUpload.addTargetObj(el);
                })

                setDelete(clone.querySelector('.item.image .delete'))

                document.querySelector(`.js-list-${targetKey}`).appendChild(clone);
            }
        }
        $(obj.target).attr('data-cnt', selectCount)
    }

    $('select[name="high_count"]').change((e) => {
        setBoxParts(e, 'high')
    })

    $('select[name="real_count"]').change((e) => {
        setBoxParts(e, 'real')
    })
}

export default main;