import FileUpload from './fileUpload'
import { triggerEvent } from './helpers'
import md5 from 'js-md5';

export default class extends FileUpload {
    constructor(params) {
        let tmp = Object.assign({}, params, {
            uploadPath: '/api/file/upload/image',
            uploadDir: 'entry',
            ajaxSetup: {
                headers: {
                    'X-CSRF-TOKEN': params.token
                }
            },
            token: null,
        })
        super(tmp)
    }

    async loadedFile(element, reader, fileData) {
        const hash = md5(reader.result);
        let url = URL.createObjectURL(fileData)
        this.settings.prevPath = $(element).find('.photo figure img').attr('src')
        $(element).find('.photo figure').remove();
        $(element).find('.preview').addClass('is-selected is-uploading');
        let imgObj = $('<img />').attr({
            'src': url,
            'data-hash': hash
        })
        imgObj = $('<figure />').show().append(imgObj);
        $(element).find('.preview .photo').prepend(imgObj);
        $(element).find('.preview .photo p').text(fileData.name)
    }

    uploadedFile(element, resp) {
        $(element).find('.preview').removeClass('is-uploading')
        $(element).find('input.input_photo').val(resp.file.key)
        $(element).find('input.input_photo_name').val(resp.file.name)
        triggerEvent($(element).find('input.input_photo').get(0), 'change')
    }

    deletedFile(element, resp) {
        $(element).find('.photo figure').remove();
        $(element).find('input.input_photo').val('')
        $(element).find('input.input_photo_name').val('')
        $(element).find('.preview').removeClass('is-selected');
        triggerEvent($(element).find('input.input_photo').get(0), 'change')
        $(element).find('input[type="file"]').val('')
    }
    setCloneObj(obj) {
        obj.find('.photo figure').remove()
    }
    error(element, params = null) {
        if (this.settings.prevPath) {
            $(element).find('.photo figure').removeClass('uploading')
                .find('img').attr('src', this.settings.prevPath)
        } else {
            $(element).find('.photo figure').remove()
        }
        if('error' in params){
            if("description" in params.error){
                alert(params.error.description)
            }else{
                alert("ファイルのアップロードに失敗しました。\nファイルの形式やフォーマットをご確認ください。")
            }
        }
    }
}