export default class {
    constructor(params) {
        this.settings = Object.assign({}, this.defaults(), params)
        $(this.settings.targetSelector).each((idx, element) => {
            this.addTargetObj(element)
            let obj = $(element).find('input[type="hidden"]')
            if (obj.val()) {
                obj.siblings('.preview').show();
            }
        })
    }
    defaults() {
        return {
            targetSelector: '.item.image',
            uploadPath: '/api/file/upload', // 通常アップロード
            uploadUrlPath: '/api/file/uploadUrl', // ファイルurlを指定してのアップロード
            uploadKeyName: 'file',
            deleteAjax: false,
            deletePath: '/api/file/del',
            deleteSelector: '.del',
            typeFilter: [],
            fileSizeLimit: null,
            token: null,
            uploadDir: '/',
            ajaxSetup: {},
            ajaxAddData: null,
        }
    }

    addTargetObj(element) {
        let fileObj = $(element).find('input[type="file"]')
        fileObj.on('change.fileUpload', event => {
            this.loadLocalImage(element, event)
        })
        $(element).find(this.settings.deleteSelector).on('click', event => {
            let path = $(element).find('input[type="hidden"]').val()
            this.delete(path)
                .then(resp => {
                    this.deletedFile(element, resp)
                })
        })
    }

    loadLocalImage(element, event) {
        const targetObj = event.currentTarget;
        // ファイル情報を取得
        const fileData = event.target.files[0];
        // フィルター処理
        if (!fileData) return;
        let errors = this.settings.typeFilter.filter(val => {
            if (!fileData.type.match(val)) {
                return true;
            }
        });
        if (errors.length) {
            alert('指定されたファイルタイプは選択できません')
            return;
        }

        // ファイルサイズ制限
        if (fileData.size > this.settings.fileSizeLimit * 1048576) { // 1024 * 1024
            alert(`${this.settings.fileSizeLimit}MB以内のファイルを選択してください。`)
            return;
        }

        // FileReaderオブジェクトを使ってファイル読み込み
        const reader = new FileReader();

        // ファイル読み込みに成功したときの処理
        reader.onload = async () => {
            this.loadedFile(element, reader, fileData)
            if (!this.checkFile(element, reader, fileData)) return false;
            const resp = await this.upload(fileData, element, targetObj)
                .catch(resp => {
                    this.error(element, resp)
                })
            this.uploadedFile(element, resp, fileData)
        }
        // ファイル読み込みを実行
        reader.readAsDataURL(fileData);
    }
    loadUrl(url, renderObj) {
        this.uploadUrl(url)
            .then(resp => {
                this.uploadedFile(renderObj, resp)
            })
            .catch(error => {
                this.error(element)
            })
    }

    upload(fileData, element, targetObj) {
        const fd = new FormData();
        fd.append(this.settings.uploadKeyName, fileData)
        if (this.settings.token) {
            fd.append(this.settings.token.key, this.settings.token.value)
        }
        if (this.settings.ajaxAddData) {
            Object.keys(this.settings.ajaxAddData).forEach(_key => {
                fd.append(_key, this.settings.ajaxAddData[_key])
            })
        }
        fd.append('uploadDir', this.settings.uploadDir)
        let ajaxParams = Object.assign({
            url: this.settings.uploadPath,
            type: 'post',
            dataType: 'json',
            data: fd,
            processData: false,
            contentType: false,
            xhr: () => {
                const xhr = new window.XMLHttpRequest()
                xhr.upload.addEventListener("progress", (evt) => {
                    if (evt.lengthComputable) {
                        let percent = evt.loaded / evt.total * 100
                        console.log(`${percent}% uploaded.`)
                        this.uploading(element, percent)
                    }
                }, false)
                return xhr
            },
        }, this.settings.ajaxSetup)

        return new Promise((resolve, reject) => {
            $.ajax(ajaxParams)
                .done(res => {
                    targetObj.value = ''
                    if ('error' in res) {
                        reject(res)
                    } else {
                        resolve(res)
                    }
                })
                .fail((jqXHR, statusText, errorThrown) => {
                    reject(errorThrown)
                });
        })
    }
    async uploadUrl(url) {
        const fd = new FormData();
        fd.append('url', url)
        fd.append('uploadDir', this.settings.uploadDir)

        let params = {
            method: 'POST',
            credentials: 'same-origin',
            body: fd,
        }
        Object.assign(params, this.settings.ajaxSetup)

        let resp = await fetch(this.settings.uploadUrlPath, params)
        return resp.json()
    }

    delete(path) {
        return new Promise((resolve, reject) => {
            if (this.settings.deleteAjax) {
                let ajaxData = { path }
                if (this.settings.token) {
                    Object.assign(ajaxData, this.settings.token)
                }
                let ajaxParams = Object.assign({
                    url: this.settings.deletePath,
                    type: 'post',
                    dataType: 'json',
                    data: ajaxData,
                }, this.settings.ajaxSetup)
                $.ajax(ajaxParams)
                    .done(function (res) {
                        resolve(res)
                    })
                    .fail(function (jqXHR, statusText, errorThrown) {
                        reject(errorThrown)
                    });
            } else {
                resolve()
            }
        })
    }
    uploading(element, percent) {
        return null
    }
    loadedFile(element, reader) {
        return null
    }
    checkFile(element, reader, fileData) {
        return true;
    }
    uploadedFile(element, resp) {
        return null
    }
    deletedFile(element, resp) {
        return null
    }
    error(element, params = null) {
        alert('アップロードエラー')
    }
}